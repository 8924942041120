<template>
  <CHeader with-subheader>
    <div
      id="V2HeaderWrap"
      class="admin_header navigation"
      :class="{ dark_mode: $store.state.darkHeader }"
    >
      <div class="right-menu__wrapper">
        <ul class="right-menu">
          <HeaderDropdownArtists />
          <HeaderDropdownW3 />
          <router-link
            tag="li"
            to="/little-picasso"
            active-class="active"
            class="shinhan"
          >
            <a>
              {{ $t("menu.picasso") }}
              <div class="shinhan-logo" />
            </a>
          </router-link>
          <HeaderDropdownArtpool />
          <HeaderDropdownAbout />
        </ul>
        <ul class="button-wrapper">
          <HeaderDropdownWallet ref="headerDropdownWallet" />
        </ul>
      </div>
    </div>
  </CHeader>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import HeaderDropdownArtists from "@/containers/HeaderDropdownArtists";
import HeaderDropdownArtpool from "@/containers/HeaderDropdownArtpool";
import HeaderDropdownAbout from "@/containers/HeaderDropdownAbout";
import HeaderDropdownWallet from "@/containers/HeaderDropdownWallet";

export default {
  name: "HeaderAdmin",
  components: {
    HeaderDropdownArtists,
    HeaderDropdownArtpool,
    HeaderDropdownAbout,
    HeaderDropdownWallet
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
  }
};
</script>
